@import "../../../assets/scss/colors";
@import "../../../assets/scss/fonts";

.custom-checkbox {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	-webkit-user-select: none;
  	-moz-user-select: none;
  	-ms-user-select: none;
  	user-select: none;
	font-family: $OpenSans;
	font-size: 1.5vh;
	color: $blue;
	margin: 1vh 0;

	& input {
		position: absolute;
		margin: 0;
  		opacity: 0;
  		width: 2vh;
		height: 2vh;
		border: 1px solid transparent;
		z-index: 2;
		cursor: pointer;
	}
	& input:checked ~ .box > .check {
		opacity: 1;
	}

	& .box {
		width: 1.8vh;
		height: 1.8vh;
		margin-right: 1vh;
		z-index: 1;
		transition: background-color 250ms ease;
		
		& .check {
			position: absolute;
			opacity: 0;
			transition: opacity 150ms ease;
			height: auto;
			width: 2.5vh;
			top: -.5vh;
		}
	}

	// If color === "transparent"
	&.transparent {
		& .box {
			border: 1px solid $tertiary;
			background-color: transparent;

			& .check {
				fill: $blue;
			}
		}

		&:hover > .box {
			background-color: $tertiary;
		}
		
		& input:checked ~ .box {
			background-color: $tertiary;
		}
	}

	// If color === "colored"
	&.colored {
		& .box {
			border: 1px solid $blue;
			background-color: $secondary;

			& .check {
				fill: $tertiary;
			}
		}

		&:hover > .box {
			background-color: $blue;
		}

		& input:checked ~ .box {
			background-color: $blue;
		}
	}
}