$primary: #254BAD;
$secondary: #EEDFD2;
$tertiary: rgb(204,219,255);
$quaternary: #000;
$quinary: #373737;
$color-sixt:#bfbfbf;
$color-seven:#979797;
$color-eight: #408CFF;
$color-nine: #7600D3;
$color-ten: #C4C4C4;
$color-eleven: #F8C670;
$color-twelve : #3CA466;
$color-thirteen : #4B6AB9;
$color-fourteen : #1D1D1D;

$white: #FDFDFD;
$purewhite: #FFF;
$lightPink: #FCFAF8;
$lightBlue: #A0BBC3;
$grey: grey;
$orangeSoft: #F9B77B;
$blueGreen: #000;
$green: #007F5F;
$red: #D75D5D;
$black: #000;
$blackblue: #151819;
$blue:#000;


// Strategies
$placement: #1A7D6A;
$developpement: #F7B67B;
$equilibree: #2577A8;

$graph1: #3B4865;
$graph2: #6C768B;
$graph3: #9DA3B2;
$graph4: #8F00FF;

$color1: #A4A4A4;
$color2:#408CFF;
$color3:#414D51;