@import "../../assets/scss/colors";
@import "../../assets/scss/fonts";

.not-found-container {
    display: flex;
    flex-direction: row;
    height: 80vh;
    width: 100%;
    justify-content: space-around;
    align-items: flex-end;
    overflow: hidden;
    padding-left: 15vw;

    & .svg-404-container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 86%;

        & svg {
            width: 80vh;
            height: auto;
        }
    }

    & .text-404-container {
        width: 50%;
        height: 86%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        & .text-wrapper {
            width: max-content;
            height: max-content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            left: 15vh;

            & .error-404,
            .half-turn {
                color: $blue;
                text-align: center;
            }

            & .error-404 {
                margin: 0;
                margin-bottom: 2.5vh;
                font-family: $OpenSans;
                font-size: 4.5vh;
                letter-spacing: 7px;
                font-weight: lighter;
            }

            & .half-turn {
                font-family: $OpenSans;
                font-weight: normal;
                font-size: 4vh;
                margin: 0;
                margin-bottom: 4.5vh;
            }

            & .link-redirect-to-bevouac {
                font-family: $OpenSans;
                position: relative;
                display: flex;
                flex-direction: column;
                color: $tertiary;
                font-size: 2.2vh;
                text-decoration: none;
                cursor: pointer;
                transition: color 250ms ease;

                & .underline {
                    margin: 0;
                    position: absolute;
                    bottom: 0;
                    background-color: $tertiary;
                    height: 1px;
                    width: 50vw;
                }

                &:hover {
                    color: $orangeSoft;
                }
            }
        }
    }
}

/* MOBILE VERSION */
.not-found-container.mobile {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .svg-404-container {
        position: relative;
        top: 20%;
        height: 30%;
        margin: 0 auto;
    }

    & .text-404-container {
        width: 100%;

        & .text-wrapper {
            left: unset;
            margin: 0 auto;
        }
    }
}
