@import "../../../assets/scss/colors";

.progress-bar-container {
	position: absolute;
    bottom: 0;
    z-index: 999;
    width: 100%;

	& .progress-bar {
		background-color: rgba($color: $purewhite, $alpha: 0.1);

		& .MuiLinearProgress-bar1Determinate {
			background-color: $purewhite;
		}
	}
}