@import "../../../assets/scss/colors";
@import "../../../assets/scss/fonts";

// MODULES

@import "../../../assets/scss/modules/media-queries";
@import "../../../assets/scss/init/vars";
.panel1a-border {
	& .visible-text  {
		justify-content: flex-end;
		font-size:0.875rem;
    	font-weight: 400;

		@include max-screen( $tablet ) {
			font-size: 1.5vh;
			font-weight: 700;
		}

		& .bubble-info {
			margin-left: 0vh;
			margin-right: 2vh;
		}
		& .tooltip,
		& .tooltip1 {
			@include max-screen( $tablet ) {
				max-width: 44vh;
				left: -10vh;
			}
		}
		& .left {
			&::before {
				@include max-screen( $tablet ) {
					left: -4%;
					bottom: 40%;
					right:auto;
					transform: rotate(-90deg);
					margin-right: -10px;
				}
			}
			@include max-screen( $tablet ) {
				top: -8vh;
				left: 5vh;
			}
		}
	}
	& .visible-info  {
		justify-content: flex-start;
	}

}
.visible-text {
	margin: 0;
	font-family: $WorkSans;
	color: $black;
	font-size: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	position: relative;

	
	& .bubble-info {
		position: relative;
		float: right;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 1vh;
		margin-right: 2vh;
		color: $purewhite;
		font-family: $WorkSans;
		font-size: 1rem;
		cursor: pointer;
		width: 16px;
		height: 16px;
		@include max-screen( $tablet ) {
			font-size: 0.7rem;
			width: 12px;
			height: 12px;
		}

		&::before {
			content: "i";
			font-weight:600;
			color: $black;
			padding-left:8px;
			font-size:0.75rem;
			@include max-screen( $tablet ) {
				font-size: 0.6rem;
				padding-left:7px;
				padding-top:2px;
			}
		}

		& span {
			position: absolute;
			top: -2px;
			border: 2px solid $black;
			border-radius: 50%;
			height: 16px;
			width: 16px;
			@include max-screen( $tablet ) {
				width: 12px;
				height: 12px;
			}
		}
	}

	& .bubble-info:hover ~ .tooltip {
		opacity: 1;

		
	}
	
	& .tooltip {
		position: absolute;
		left: -18vh;
		top: 4vh;
		width: max-content;
		max-width: 40vh;
		opacity: 0;
		transition: opacity 250ms ease;
		background-color: $black;
		z-index: 10;
		display: none;
		border-radius:5px;
		@include max-screen( $tablet ) {
			max-width: 24vh;
			left: -22vh;
		}

		&.hovered-close:active {
			display:none;
		}

		&.hovered {
			display: flex;
			flex-direction: column;
			opacity: 1;
			padding:5%;
			& .closeTooltip {
				display:none;
				@include max-screen( $tablet ) {
					color:$purewhite;
					text-align: right;
					font-size: 1rem;
					padding:0 5%;
					display:block;
				}
			}
			
			
		}

		&::before {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -10px;
			border-width: 10px;
			border-style: solid;
			border-color: transparent transparent $black transparent;
			@include max-screen( $tablet ) {
				border-color: transparent transparent transparent transparent;
			}
		}

		& p {
			margin: 0;
			display: inline-block;
			padding: 1vh 2vh;
			font-family: $WorkSans;
			font-weight: 600;
			font-size: 0.65rem;
			color: $purewhite;
			text-align: left;
		}
	}
	& .tooltip-dpe {
		@include max-screen( $tablet ) {
			left: -11vh;
		}
	}
	
}
.pills-container:nth-child(odd){
	& .left {
		&::before {
			@include max-screen( $tablet ) {
				left: -6%;
				bottom: 40%;
				right:auto;
				transform: rotate(-90deg);
				margin-right: -10px;
			}
		}
		@include max-screen( $tablet ) {
			top: -8vh;
			left: 5vh;
		}
	}
}
.pills-container:nth-child(even){
	& .left {
		&::before {
			@include max-screen( $tablet ) {
				right: -12%;
				bottom: 40%;
				left:auto;
				transform: rotate(90deg);
				margin-left: -10px;
			}
		}
		@include max-screen( $tablet ) {
			top: -7vh;
			left: -28vh;
		}
	}
}