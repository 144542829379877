@import "../../../assets/scss/colors";
@import "../../../assets/scss/fonts";

.toggle-section-charges {
    background: $purewhite;
    border: 1px solid $purewhite;
    border-radius: 5px;
}  
.toggle-section-0 {
    color:$color-ten;
}  
.switch-svg-container {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:0%;

    & .label {
        margin: 0;
        font-family: $PlayfairSemiBold;
        color: $blue;
        font-size: 1.5vh;
        text-align: center;
    }

    & .input-switch-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 3vh;
        margin-top:0%;

        & .switch-svg {
            position: absolute;
            width: 7vh;
            height: auto;
            z-index: 1;

            & path {
                fill: rgba($color-ten, 0.8);

                &.active {
                    fill: $blue;
                }
            }

            & .circle {
                fill: $purewhite;
                border:$blue;
                transition: all 250ms ease;

                &.active {
                    fill: $purewhite;
                    border:$purewhite;
                    transform: translateX(10px);
                }
            }
        }

        & .checkbox-switch {
            position: absolute;
            z-index: 2;
            width: 5vh;
            height: 100%;
            opacity: 0;
            margin: 0;
            cursor: pointer;
        }
    }
}
