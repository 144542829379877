@import "../../../assets/scss/colors";
@import "../../../assets/scss/fonts";

.map-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // width: 55%;
  width: 100%;
  height: 50vh;
  min-height: 50vh;

  & .google-map-container {
    position: relative;
    width: 100%;
    height: 100%;

    & .google-map {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background: #ffffff;
    
    }
  }

  & .map-controls {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 65%;
    margin-left: 1vh;
    margin-top: 1vh;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 2px rgba(33, 33, 33, 0.4);

    & .label > span {
      font-family: $OpenSans;
      color: $blue;
      font-size: 1.5vh;
    }
  }
}

/* MOBILE VERSION */
.map-wrapper.mobile,
.map-wrapper.split-screen {
  width: 90%;
  height: 50vh;
  padding: 0;
  margin-bottom: 3vh;

  & .gm-fullscreen-control {
    top: unset !important;
    right: unset !important;
    bottom: 0;
    left: 0;
    margin-bottom: 3vh !important;
  }
}
.map-wrapper.mobile {
  & .map-controls {
    width: 95%;
  }
}
.map-wrapper.split-screen {
  & .map-controls {
    width: 65% !important;
  }
}
