@import "../../assets/scss/colors";


.carouselMobile {
	padding-top:12vh;
}


.home-container {


	&.reduced > #carousel {
		position: relative;
		margin-top: 4vh;
	}

	& #carousel {
		position: relative;
		margin-top: -30vh;
	}

	& #details-of-rent,
	#co-property,
	#home-improvments,
	#neighborhood,
	#synthesis {
		margin: 7vh 31vh;
		margin-top: 9vh;
		& button {
			padding:12px 0px;
		}
	}

	& #synthesis {
		margin-bottom: 0;
	}

	& #balance-sheet {
		& .divider-container {
			margin-left: 0vh;
			width: 100%;
		}

		& .balance-sheet-global-container {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			margin-top: 5vh;
		}
	}

	& .bevouac-approuval {
		margin-top: 15vh;
		margin-bottom: 7vh;

		& .text-wrapper {
			& .name {
				text-transform: none;
			}
	
			& .logo {
				position: relative;
				bottom: 1vh;
				width: max-content;
				height: max-content;
	
				& svg {
					width: 20vh;
					height: auto;
				}
			}
		}
	}

	& .modal-container {
		position: absolute;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		height: 100%;
		width: 100%;
	}
}

/* MOBILE VERSION */
.home-container.mobile {
	width: 100vw;

	& .divider-container .text-wrapper {
		margin: 0 1.5vh;
	}

	& #carousel {
		
		margin-top: -29vh;
		margin-bottom: 8vh;
	}
	& #carouselMobile {
		background-color: red;
		margin-top: -29vh;
		margin-bottom: 8vh;
	}
	&.reduced > #carousel {
		margin-top: 5vh;
	}

	& #details-of-rent,
	#co-property,
	#home-improvments,
	#neighborhood,
	#synthesis {
		margin: 5vh 0vh;
	}

	& #balance-sheet {
		& .divider-container {
			margin-left: 0vh;
			width: 100%;
		}

		& .balance-sheet-global-container {
			width: 100%;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	& .bevouac-approuval {
		margin-top: 5vh;

		& span {
			position: relative;
			top: .1vh;
			opacity: 0;
		}

		& .text-wrapper {
			flex-direction: column;
			margin: 0 1vh;
			border: 2px solid $blue;
			padding: 1vh 2vh;

			& .name {
				font-size: 2.5vh;
				margin-left: 1vh;
			}
		
			& .logo {
				margin-top: 1vh;

				& svg {
					width: 15vh !important;
				}
			}
		}
	}
}