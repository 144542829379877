$PoppinsSemiBold: "Poppins SemiBold";
$OpenSans: "OpenSans Regular";
$OpenSansLight: "OpenSans Light";
$OpenSansSemiBold: "OpenSans SemiBold";
$OpenSansBold: "OpenSans Bold";
$Playfair: "Playfair Display Regular";
$PlayfairMedium: "Playfair Display Medium";
$PlayfairSemiBold: "Playfair Display SemiBold";
$PlayfairBold: "Playfair Display Bold";
$WorkSans: "WorkSans Regular";
$WorkSansMedium: "WorkSans Medium"; // 500
$WorkSansLight: "WorkSans Light";
$WorkSansSemiBold: "WorkSans Semi Bold"; //600

@font-face {
	font-family: "Poppins SemiBold";
	src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
	font-display: swap;
}
@font-face {
	font-family: "OpenSans Regular";
	src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
	font-weight: normal;
	font-display: swap;
}
@font-face {
	font-family: "OpenSans Light";
	src: url("../fonts/OpenSans-Light.ttf") format("truetype");
	font-display: swap;
}
@font-face {
	font-family: "OpenSans SemiBold";
	src: url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
	font-display: swap;
}
@font-face {
	font-family: "OpenSans Bold";
	src: url("../fonts/OpenSans-Bold.ttf") format("truetype");
	font-display: swap;
}
@font-face {
	font-family: "Playfair Display Regular";
	src: url("../fonts/PlayfairDisplay-Regular.ttf") format("truetype");
	font-weight: normal;
	font-display: swap;
}
@font-face {
	font-family: "Playfair Display Medium";
	src: url("../fonts/PlayfairDisplay-Medium.ttf") format("truetype");
	font-display: swap;
}
@font-face {
	font-family: "Playfair Display SemiBold";
	src: url("../fonts/PlayfairDisplay-SemiBold.ttf") format("truetype");
	font-display: swap;
}
@font-face {
	font-family: "Playfair Display Bold";
	src: url("../fonts/PlayfairDisplay-Bold.ttf") format("truetype");
	font-display: swap;
}
@font-face {
	font-family: "WorkSans Regular";
	src: url("../fonts/WorkSans-Regular.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "WorkSans Medium";
	src: url("../fonts/WorkSans-Medium.ttf") format("truetype");
	font-display: swap;
}
@font-face {
	font-family: "WorkSans Light";
	src: url("../fonts/WorkSans-Light.ttf") format("truetype");
	font-display: swap;
}
@font-face {
	font-family: "WorkSans Semi Bold";
	src: url("../fonts/WorkSans-SemiBold.ttf") format("truetype");
	font-display: swap;
}
